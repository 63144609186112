@font-face {
    font-family: 'SPACE-FONT';
    font-style: normal;
    font-weight: normal;
    src: local('SPACE-FONT'), url('./components/SpaceMono-BoldItalic.ttf') format('truetype');
}
.leaflet-container {
    width: 100%;
    height: 100vh;
}
.iss-icon {
    width: 40px;
    height: auto;
}
.site-title {
    font-family: 'SPACE-FONT';
    color: white;
    text-align: center;
    size: 2.2rem;  
}
.site-load {
    font-family: 'SPACE-FONT';
    color: white;
    text-align: center;
    size: 2.5rem;
}
